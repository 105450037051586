<template>
  <div class="guan-jia-div">
    <i class="iconfont guan-jia-ison icon-guanjia"></i>
  </div>
</template>

<script>
export default {
  name: 'GuanJiaIcon',
}
</script>

<style scoped lang="less">
.guan-jia-div{
  //height: 100%;
  line-height: 30px;
  padding-top: 4px;
}
.guan-jia-ison {
  color: #fff;
  font-size: 20px;
  font-weight: bold;

}
</style>
