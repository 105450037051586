<template>
  <div>
    <div :class="{'fu-dong':fixed}">
      <div class="footer-list">
        <div class="ne-rong" v-for="vo in navList" :key="vo.id">
          <div class="ne-rong-div" @click="tiaoZhuan(vo)" :class="vo.pathName">
            <div class="icon">
              <component :is="vo.icon" :size="vo.size" :fill="pathName===vo.pathName?vo.actionColor:vo.color"/>
            </div>
            <div class="name" :style="{color:pathName===vo.pathName?vo.actionColor:vo.color}">
              {{ vo.title }}
            </div>
          </div>
        </div>
        <fa-bu-xu-qiu ref="faBu"/>
      </div>
    </div>
    <div class="zhan-wei" v-if="fixed">
    </div>
  </div>
  <guan-jia ref="guanJia"/>
</template>

<script>
import {Home, Delivery, Plus, Editor, User} from '@icon-park/vue-next';
import FaBuXuQiu from "./FaBuXuQiu";
import GuanJia from "@/components/select/GuanJia";
import GuanJiaIcon from "@/components/icon/GuanJiaIcon";

export default {
  name: 'footerNav',
  components: {
    GuanJia,
    FaBuXuQiu,
    iconHome: Home,
    iconDelivery: Delivery,
    iconPlus: Plus,
    iconEditor: Editor,
    iconUser: User,
    GuanJiaIcon: GuanJiaIcon
  },
  props: {
    fixed: Boolean,
    pathName: String
  },
  data() {
    return {
      navList: [
        {
          title: '首页',
          pathName: 'Index',
          icon: 'icon-home',
          color: '#989898', //默认颜色
          size: 20, //图标大小
          actionColor: '#4A4096'//选择颜色
        },
        {
          title: '找服务',
          pathName: 'FuWuDaTing',
          icon: 'icon-delivery',
          color: '#989898',
          size: 20,
          actionColor: '#4A4096'//选择颜色
        }, {
          title: '管家',
          pathName: 'faBu',
          icon: 'guan-jia-icon',
          color: '#fff',
          size: 28,
          actionColor: '#4A4096'//选择颜色
        }
        , {
          title: '惠企政策',
          pathName: 'PolicyIndex',
          icon: 'icon-editor',
          color: '#989898',
          size: 20,
          actionColor: '#4A4096'//选择颜色
        }, {
          title: '我的',
          pathName: 'UserHome',
          icon: 'icon-user',
          color: '#989898',
          size: 20,
          actionColor: '#4A4096'//选择颜色
        }
      ]
    }
  },
  methods: {
    tiaoZhuan(navListItem) {
      if (navListItem.pathName === 'faBu') {
        this.$refs.guanJia.show()
        return
      }

      this.$router.push({name: navListItem.pathName})
    }
  }
}
</script>

<style scoped lang="less">
@import "../../assets/basis_css/style/var.less";

@div-height: 55px;
.footer-list {
  z-index: 17;
  height: @div-height;
  background-color: #fff;
  box-shadow: 0 -2px 4px #ecdddd;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: flex-end;

  .ne-rong {
    text-align: center;
    //flex: 1;
    width: 100%;
    position: relative;

    /*  .zhe-zhao.faBu {
        position: absolute;
        width: 100%;
        height: 30px;
        background-color: @white;
        top: 0;
        left: 0;
        right: 0;
      }*/

    .ne-rong-div {
      padding-bottom: 5px;

      &.faBu {
        position: absolute;
        top: -85px;
        border-radius: 100px;
        left: 0;
        right: 0;
        background-color: @white;
        width: 58px;
        height: 58px;
        margin: auto;
        box-shadow: 0 -2px 4px #f3e9e9;
        z-index: 10;

        .icon {
          margin-top: 10px;
          margin-left: 10px;
          width: 38px;
          height: 38px;
          background-color: @blue;
          border-radius: 100px;
          line-height: 54px;
          text-align: center;
        }

        .name {
          color: @blue !important;
          margin-top: 5px;
          font-size: 14px;
        }
      }

      .name {
        font-size: 12px;
      }
    }

  }
}

.fu-dong {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 10;
}

.fixed {
  height: @div-height+10;
}
.zhan-wei{
  height: 65px;
}

</style>
